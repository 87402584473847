<template>
  <div>
    <!-- BREADCRUMB -->
    <section class="py-4 breadcrumb-bg" style="background-image: url(assets/img/background/page-title-bg-img6.jpg); ">
      <div class="container">
        <div class="breadcrumb-holder wow fadeInUp">
          <div>
            <h1 class="breadcrumb-title">排行榜</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                排行榜
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-4 py-md-6">
      <div class="container">
        <div class="row">
          <div class="col-12 mb-8">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li @click="setQuery('/rank?q=kindergarten')" class="nav-item">
                <a ref="kindergartenNav" class="nav-link" id="pills-kindergarten-tab" data-toggle="pill" href="#pills-kindergarten" role="tab" aria-controls="pills-kindergarten"
                aria-selected="true">幼幼王</a>
              </li>
              <li @click="setQuery('/rank?q=elementary')" class="nav-item">
                <a ref="elementaryNav" class="nav-link" id="pills-elementary-tab" data-toggle="pill" href="#pills-elementary" role="tab" aria-controls="pills-elementary"
                aria-selected="false">國小王</a>
              </li>
              <li @click="setQuery('/rank?q=secondary')" class="nav-item">
                <a ref="secondaryNav" class="nav-link" id="pills-secondary-tab" data-toggle="pill" href="#pills-secondary" role="tab" aria-controls="pills-secondary"
                aria-selected="false">國中王</a>
              </li>
              <li @click="setQuery('/rank?q=high')" class="nav-item">
                <a ref="highNav" class="nav-link" id="pills-high-tab" data-toggle="pill" href="#pills-high" role="tab" aria-controls="pills-high"
                aria-selected="false">高中王</a>
              </li>
              <li @click="setQuery('/rank?q=point')" class="nav-item">
                <a ref="pointNav" class="nav-link" id="pills-point-tab" data-toggle="pill" href="#pills-point" role="tab" aria-controls="pills-point"
                aria-selected="false">點數王</a>
              </li>
            </ul>
            <div class="tab-content py-4" id="pills-tabContent">
              <div ref="kindergartenItem" class="tab-pane fade" id="pills-kindergarten" role="tabpanel" aria-labelledby="pills-kindergarten-tab">
                <div class="nicdark_textevidence nicdark_bg_grey nicdark_shadow nicdark_radius left overflow_scroll">
                  <table class="nicdark_table extrabig nicdark_bg_yellow nicdark_radius ">
                    <thead class="nicdark_border_yellow">
                      <tr>
                        <td><h5 class="text-white text-center">排名</h5></td>
                        <td><h5 class="text-white text-center">挑戰者</h5></td>
                        <td><h5 class="text-white text-center">積分</h5></td>
                      </tr>
                    </thead>
                    <tbody class="nicdark_bg_grey nicdark_border_grey">
                      <template v-if="rankData.class1.length">
                        <tr v-for="(item, index) in rankData.class1" :key="index">
                          <td><h5 class="text-center">{{ `#${index + 1}` }}</h5></td>
                          <td><img :src="item.icon" :alt="item.name" class="rank__user-avatar cart-image d-none d-md-inline-block mr-md-5">{{ item.name }}</td>
                          <td>{{ item.total_score }}</td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td></td>
                        <td><h5 class="text-center my-5">排行榜資料統計中</h5></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div ref="elementaryItem" class="tab-pane fade" id="pills-elementary" role="tabpanel" aria-labelledby="pills-elementary-tab">
                <div class="nicdark_textevidence nicdark_bg_grey nicdark_shadow nicdark_radius left overflow_scroll">
                  <table class="nicdark_table extrabig nicdark_bg_yellow nicdark_radius ">
                    <thead class="nicdark_border_yellow">
                      <tr>
                        <td><h5 class="text-white text-center">排名</h5></td>
                        <td><h5 class="text-white text-center">挑戰者</h5></td>
                        <td><h5 class="text-white text-center">積分</h5></td>
                      </tr>
                    </thead>
                    <tbody class="nicdark_bg_grey nicdark_border_grey">
                      <template v-if="rankData.class2.length">
                        <tr v-for="(item, index) in rankData.class2" :key="index">
                          <td><h5 class="text-center">{{ `#${index + 1}` }}</h5></td>
                          <td><img :src="item.icon" :alt="item.name" class="rank__user-avatar cart-image d-none d-md-inline-block mr-md-5">{{ item.name }}</td>
                          <td>{{ item.total_score }}</td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td></td>
                        <td><h5 class="text-center my-5">排行榜資料統計中</h5></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div ref="secondaryItem" class="tab-pane fade" id="pills-secondary" role="tabpanel" aria-labelledby="pills-secondary-tab">
                <div class="nicdark_textevidence nicdark_bg_grey nicdark_shadow nicdark_radius left overflow_scroll">
                  <table class="nicdark_table extrabig nicdark_bg_yellow nicdark_radius ">
                    <thead class="nicdark_border_yellow">
                      <tr>
                        <td><h5 class="text-white text-center">排名</h5></td>
                        <td><h5 class="text-white text-center">挑戰者</h5></td>
                        <td><h5 class="text-white text-center">積分</h5></td>
                      </tr>
                    </thead>
                    <tbody class="nicdark_bg_grey nicdark_border_grey">
                      <template v-if="rankData.class3.length">
                        <tr v-for="(item, index) in rankData.class3" :key="index">
                          <td><h5 class="text-center">{{ `#${index + 1}` }}</h5></td>
                          <td><img :src="item.icon" :alt="item.name" class="rank__user-avatar cart-image d-none d-md-inline-block mr-md-5">{{ item.name }}</td>
                          <td>{{ item.total_score }}</td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td></td>
                        <td><h5 class="text-center my-5">排行榜資料統計中</h5></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div ref="highItem" class="tab-pane fade" id="pills-high" role="tabpanel" aria-labelledby="pills-high-tab">
                <div class="nicdark_textevidence nicdark_bg_grey nicdark_shadow nicdark_radius left overflow_scroll">
                  <table class="nicdark_table extrabig nicdark_bg_yellow nicdark_radius ">
                    <thead class="nicdark_border_yellow">
                      <tr>
                        <td><h5 class="text-white text-center">排名</h5></td>
                        <td><h5 class="text-white text-center">挑戰者</h5></td>
                        <td><h5 class="text-white text-center">積分</h5></td>
                      </tr>
                    </thead>
                    <tbody class="nicdark_bg_grey nicdark_border_grey">
                      <template v-if="rankData.class4.length">
                        <tr v-for="(item, index) in rankData.class4" :key="index">
                          <td><h5 class="text-center">{{ `#${index + 1}` }}</h5></td>
                          <td><img :src="item.icon" :alt="item.name" class="rank__user-avatar cart-image d-none d-md-inline-block mr-md-5">{{ item.name }}</td>
                          <td>{{ item.total_score }}</td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td></td>
                        <td><h5 class="text-center my-5">排行榜資料統計中</h5></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div ref="pointItem" class="tab-pane fade" id="pills-point" role="tabpanel" aria-labelledby="pills-point-tab">
                <div class="nicdark_textevidence nicdark_bg_grey nicdark_shadow nicdark_radius left overflow_scroll">
                  <table class="nicdark_table extrabig nicdark_bg_yellow nicdark_radius ">
                    <thead class="nicdark_border_yellow">
                      <tr>
                        <td><h5 class="text-white text-center">排名</h5></td>
                        <td><h5 class="text-white text-center">挑戰者</h5></td>
                        <td><h5 class="text-white text-center">獲得點數</h5></td>
                      </tr>
                    </thead>
                    <tbody class="nicdark_bg_grey nicdark_border_grey">
                      <template v-if="rankData.class5.length">
                        <tr v-for="(item, index) in rankData.class5" :key="index">
                          <td><h5 class="text-center">{{ `#${index + 1}` }}</h5></td>
                          <td><img :src="item.icon" :alt="item.name" class="rank__user-avatar cart-image d-none d-md-inline-block mr-md-5">{{ item.name }}</td>
                          <td>{{ item.total_score }}</td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td></td>
                        <td><h5 class="text-center my-5">排行榜資料統計中</h5></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
export default {
  name: 'rank',
  data () {
    return {
      rankData: {
        class1: [],
        class2: [],
        class3: [],
        class4: [],
        class5: []
      }
    }
  },
  computed: {
    q () {
      return this.$route.query.q
    }
  },
  mounted () {
    const vm = this
    vm.getRankList()
    require('@/assets/js/base.js')
    vm.setActiveTab()
  },
  methods: {
    getRankList () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/scores/rankList`

      vm.axios.post(api, { token: tokenGenerator() }).then(res => {
        this.rankData = res.data.content
      })
    },
    setQuery (link) {
      if (this.$route.fullPath === link) return null
      this.$router.push(link)
    },
    setActiveTab () {
      const { q } = this.$route.query
      if (!this.$refs[`${q}Nav`]) {
        location.href = '/rank?q=kindergarten'
      } else {
        const navLinks = this.$el.querySelectorAll('.nav-link')
        navLinks.forEach(item => item.classList.remove('active'))
        const tabPanes = this.$el.querySelectorAll('.tab-pane')
        tabPanes.forEach(item => {
          item.classList.remove('active')
          item.classList.remove('show')
        })
        this.$refs[`${q}Nav`].classList.add('active')
        this.$refs[`${q}Item`].classList.add('active', 'show')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/kidz.css";
@import "../assets/css/nicdark_shortcodes.css";
.rank__user-avatar {
  width: 100px;
  height: 100px;
}
</style>
